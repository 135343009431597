import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export function ContractCollapsedContentTenantClosed() {
  return (
    <Box sx={{ margin: 1 }}>
      <Typography variant="h6" gutterBottom component="div">
        テナント詳細
      </Typography>
      <Typography
        variant="h6"
        gutterBottom
        component="div"
        sx={{ color: 'red' }}
      >
        解約済み
      </Typography>
    </Box>
  );
}
