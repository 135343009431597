import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { getEnvConfig } from '@/config/envConfig';
import { AppBody } from './AppBody';

const envConfig = getEnvConfig();
console.log(JSON.stringify(envConfig));

Amplify.configure(envConfig);

function App() {
  return (
    <Authenticator hideSignUp>
      {({ signOut }) => (signOut ? <AppBody signOut={signOut} /> : <></>)}
    </Authenticator>
  );
}

export default App;
