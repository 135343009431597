import { Order } from '@/types/types';
export type Action =
  | {
      type: 'movePageTo';
      pageNum: number;
    }
  | {
      type: 'changeRowsPerPage';
      value: number;
    }
  | {
      type: 'sortBy';
      idx: number;
      order: Order;
    };

export type State = {
  currentPage: number;
  rowsPerPage: number;
  startIdx: number;
  endIdx: number;
  sortActiveColumn: { order: Order; idx: number } | undefined;
};

export const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'movePageTo': {
      const startIdx = action.pageNum * state.rowsPerPage;
      const endIdx = startIdx + state.rowsPerPage;
      return {
        ...state,
        currentPage: action.pageNum,
        startIdx,
        endIdx,
      };
    }
    case 'changeRowsPerPage': {
      const currentPage = 0;
      const startIdx = 0;
      const endIdx = action.value;
      return {
        ...state,
        rowsPerPage: action.value,
        currentPage,
        startIdx,
        endIdx,
      };
    }
    case 'sortBy': {
      return {
        ...state,
        sortActiveColumn: { order: action.order, idx: action.idx },
      };
    }
    default:
      throw new Error();
  }
};
