import * as React from 'react';
import {
  ClonalContractHistory,
  ClonalTenant,
} from '@/lib/graphql/clonal-appsync-apollo';
import { Maybe } from '@/types/types';
import { toLocaleString } from '@/utils/date-util';
import { slice } from '@/utils/string-util';
import {
  getReadablePaymentType,
  getTextStyleOfTrialExpired,
} from '../utils/contractUtils';
import {
  CollapsibleTableRow,
  RowItem,
} from '@/components/table/CollapsibleTableRow';
import { ContractCollapsedContent } from './ContractCollapsedContent';
import { ContractCollapsedContentTenantClosed } from './ContractCollapsedContentTenantClosed';

export const displayItems: RowItem[] = [
  { header: '契約種別', value: '' },
  { header: '会社名', value: '' },
  { header: '部署名', value: '' },
  { header: '契約開始日', value: '', sortable: true },
  { header: '契約終了日', value: '', sortable: true },
  { header: 'トライアル満了日', value: '', sortable: true },
];

type Props = {
  contract: ClonalContractHistory;
  tenant: Maybe<ClonalTenant>;
};
export function ContractTableRow(props: Props) {
  const items: RowItem[] = React.useMemo(() => {
    const tmp = [...displayItems];
    tmp[0].value = getReadablePaymentType(props.contract.paymentType);
    tmp[1].value = slice(props.tenant?.companyName || '', 10);
    tmp[2].value = slice(props.tenant?.department || '', 10);
    tmp[3].value = toLocaleString(props.contract.contractStartedAt);
    tmp[4].value = toLocaleString(props.contract.contractEndedAt || '');
    tmp[5].value = toLocaleString(props.contract.trialExpiredAt || '');
    tmp[5].sx = getTextStyleOfTrialExpired(props.contract.trialExpiredAt);
    return tmp;
  }, [props.contract, props.tenant]);

  return (
    <CollapsibleTableRow
      rowItems={items}
      collapsedContent={
        props.tenant ? (
          <ContractCollapsedContent
            contractId={props.contract.id}
            tenantId={props.tenant.id}
          />
        ) : (
          <ContractCollapsedContentTenantClosed />
        )
      }
    />
  );
}
