import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { DrawerItemKind } from './const';
import { DrawerIcon } from './DrawerIcon';
type Props = {
  itemName: DrawerItemKind;
  onClick: (itemName: DrawerItemKind) => void;
};
export const DrawerItem = (props: Props) => {
  const handleOnClick = React.useCallback(() => {
    const onClick = props.onClick;
    onClick(props.itemName);
  }, [props.itemName, props.onClick]);

  return (
    <ListItemButton onClick={handleOnClick}>
      <DrawerIcon itemKind={props.itemName} />
      <ListItemText primary={props.itemName} />
    </ListItemButton>
  );
};
