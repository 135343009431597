import * as React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { v4 as uuid4 } from 'uuid';
import { RowItem } from '@/components/table/CollapsibleTableRow';

type Props = {
  rowItems: RowItem[];
};

export function UnCollapsibleTableRow(props: Props) {
  const items = React.useMemo(() => {
    if (props.rowItems.length > 0) {
      const first = props.rowItems[0];
      const exceptFirstItems = props.rowItems.slice(1);
      return { first, exceptFirstItems };
    } else {
      return {
        first: { header: '', value: '' },
        exceptFirstItems: [{ header: '', value: '' }],
      };
    }
  }, [props.rowItems]);

  return (
    <TableRow
      sx={{
        '& > *': {
          borderBottom: 'unset',
          wordWrap: 'break-word',
          whiteSpace: 'nowrap',
        },
      }}
    >
      <TableCell component="th" scope="row">
        {items.first.value}
      </TableCell>
      {items.exceptFirstItems.map((item) => (
        <TableCell align="left" key={uuid4()}>
          {item.value}
        </TableCell>
      ))}
    </TableRow>
  );
}
