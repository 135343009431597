import React from 'react';
import { HashRouter, Route, Routes, Navigate } from 'react-router-dom';
import { DashboardPage } from './pages/DashboardPage';

export const Root = () => {
  return (
    <HashRouter>
      <Routes>
        <Route path={`/dashboard`} element={<DashboardPage />} />
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Routes>
    </HashRouter>
  );
};
