import { ApolloLink, Operation } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { createHttpLinkForCognitoAuth } from "./httpLink";
import { createSubscriptionLinkForCognitoAuth } from "./subscriptionLink";

// 判定
const isSubscription = (op: Operation) => {
  const definition = getMainDefinition(op.query);
  //console.log('☆' + JSON.stringify(op));
  //console.log('☆' + JSON.stringify(definition));
  // subscriptionならばwsLinkに振り分け
  return (
    definition.kind === "OperationDefinition" &&
    definition.operation === "subscription"
  );
};

export const createHybridLinkForCognitoAuth = async (
  httpUri: string,
  getAuthorization: () => Promise<string>
) => {
  return ApolloLink.split(
    isSubscription,
    await createSubscriptionLinkForCognitoAuth(httpUri, getAuthorization),
    await createHttpLinkForCognitoAuth(httpUri, getAuthorization)
  );
};
