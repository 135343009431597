import { DrawerItemKind } from '@/components/frame/const';
import {
  APP_BAR_TITLE_FOR_MEETING,
  APP_BAR_TITLE_FOR_USER,
} from '@/const/define';

export const transformAppBarTitle = (item: DrawerItemKind) => {
  switch (item) {
    case 'Meetings':
      return APP_BAR_TITLE_FOR_MEETING;
    case 'Users':
      return APP_BAR_TITLE_FOR_USER;
    default:
      return item as string;
  }
};
