/* eslint-disable @typescript-eslint/naming-convention */
type AmplifyConfig = {
  aws_project_region: string;
  aws_cognito_identity_pool_id: string;
  aws_cognito_region: string;
  aws_user_pools_id: string;
  aws_user_pools_web_client_id: string;
  oauth: Record<string, unknown>;
  aws_cloud_logic_custom: {
    name: string;
    endpoint: string;
    region: string;
  }[];
  aws_appsync_graphqlEndpoint: string;
  aws_appsync_region: string;
  aws_appsync_authenticationType: string;
  aws_user_files_s3_bucket: string;
  aws_user_files_s3_bucket_region: string;
  aws_mobile_analytics_app_id: string;
  aws_mobile_analytics_app_region: string;
  aws_content_delivery_bucket: string;
  aws_content_delivery_bucket_region: string;
  aws_content_delivery_url: string;
};
type AppConfig = {
  env_name: string;
};
export type EnvConfig = AmplifyConfig & AppConfig;

// デフォルトの envConfigを読み込む。ビルド条件、Electronアプリかどうかなどの条件によって変わる
function loadDefaultEnvConfig(): EnvConfig {
  try {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const env = require('./.checkout.env').default as EnvConfig;
    // console.log(JSON.stringify(env));
    return env;
  } catch {
    // do nothing
  }
  throw new Error('env file not found');
}

export function getEnvConfig() {
  return loadDefaultEnvConfig();
}

export function getEnvName(): string {
  return getEnvConfig().env_name;
}
