import * as React from 'react';
import { SxProps, Theme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { v4 as uuid4 } from 'uuid';

export type RowItem = {
  header: string;
  value: string;
  sortable?: boolean;
  sx?: SxProps<Theme>;
};

type Props = {
  rowItems: RowItem[];
  collapsedContent: React.ReactNode;
};

export function CollapsibleTableRow(props: Props) {
  const [open, setOpen] = React.useState(false);

  const items = React.useMemo(() => {
    if (props.rowItems.length > 0) {
      const first = props.rowItems[0];
      const exceptFirstItems = props.rowItems.slice(1);
      return { first, exceptFirstItems };
    } else {
      return {
        first: { header: '', value: '' },
        exceptFirstItems: [{ header: '', value: '' }],
      };
    }
  }, [props.rowItems]);

  const handleOnClick = () => {
    setOpen(!open);
  };
  return (
    <>
      <TableRow
        sx={{
          '& > *': {
            borderBottom: 'unset',
            wordWrap: 'break-word',
            whiteSpace: 'nowrap',
            backgroundColor: open ? 'lightgray' : 'inherit',
          },
        }}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            sx={{ height: '16px', width: '16px' }}
            onClick={handleOnClick}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {items.first.value}
        </TableCell>
        {items.exceptFirstItems.map((item) => (
          <TableCell align="left" key={uuid4()} sx={item.sx}>
            {item.value}
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={props.rowItems.length + 1}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            {props.collapsedContent}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
