import { SubscriptionClient, ClientOptions } from "subscriptions-transport-ws";
import { v4 as uuid4 } from "uuid";

const WEBSOCKET_STATE = {
  CONNECTING: 0,
  OPEN: 1,
  CLOSING: 2,
  CLOSED: 3,
} as const;

type ConnectingState =
  | "init"
  | "connecting"
  | "connected"
  | "reconnecting"
  | "disconnected";
// SubscriptionClientでは対応できない部分をカバーするためにPrivateをオーバーライドする
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
export class UUIDOperationIdSubscriptionClient extends SubscriptionClient {
  authFunction: () => Promise<{ host: string; Authorization: string }>;
  originalUrl: string;
  header: string;
  payload: string;
  private _reconnectingCount = 0;
  private _state: ConnectingState;
  constructor(
    url: string,
    args: ClientOptions,
    authFunction: () => Promise<{ host: string; Authorization: string }>
  ) {
    super(url, args);
    this.authFunction = authFunction;
    this.originalUrl = url;
    this.header = "";
    this.payload = Buffer.from(JSON.stringify({})).toString("base64");
    this._state = "init";
  }

  get reconnectingCount() {
    return this._reconnectingCount;
  }

  set reconnectingCount(count: number) {
    this._reconnectingCount = count;
  }

  get state() {
    return this._state;
  }

  changeState(event: ConnectingState) {
    const beforeState = this._state;
    switch (this._state) {
      case "init":
        if (event === "connecting") this._state = "connecting";
        break;
      case "connecting":
        if (event === "connected") this._state = "connected";
        break;
      case "connected":
        if (event === "reconnecting") this._state = "reconnecting";
        break;
      case "reconnecting":
        if (event === "connected") this._state = "connected";
        if (event === "disconnected") this._state = "disconnected";
        break;
      case "disconnected":
        if (event === "connecting") this._state = "connecting";
        if (event === "reconnecting") this._state = "reconnecting";
        break;
      default:
        break;
    }
    console.log(
      `SubscClient::changeState. event: ${event}, before:${beforeState}, after:${this._state}`
    );
  }

  connect = async () => {
    // 認証情報
    const headers = await this.authFunction();
    // 認証情報付WebSocket用API
    const header = Buffer.from(JSON.stringify(headers)).toString("base64");
    if (
      this.status !== WEBSOCKET_STATE.CONNECTING &&
      this.status !== WEBSOCKET_STATE.CLOSING
    ) {
      if (this.header !== header) {
        this.header = header;
      }
      //const payload = Buffer.from(JSON.stringify({})).toString('base64');
      // 期限切れの認証情報が使用されるのを避けるため接続時に非同期に認証情報を取得する
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.url = `${this.originalUrl}?header=${this.header}&payload=${this.payload}`;
      // console.log(`Connection... ${this.url}`);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      super.connect();
    }
  };

  // uuidをappsync対応型に変更
  generateOperationId() {
    return uuid4();
  }

  // Appsyncから送信される "start_ack" は処理できないので除外する必要がある
  processReceivedData(receivedData: string) {
    try {
      const parsedMessage = JSON.parse(receivedData);
      // if (parsedMessage?.type !== 'data') {
      // console.log(`[realtime-api] Recived: ${JSON.stringify(parsedMessage)}`);
      // }
      if (parsedMessage?.type === "start_ack") return;
    } catch (e) {
      throw new Error("Message must be JSON-parsable. Got: " + receivedData);
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    super.processReceivedData(receivedData);
  }
}
