import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { MEETING_OLDEST_DATE, COMMON_QUERY_LIMIT } from '@/const/define';
import { Table } from '@/components/table/Table';
import { useListUsers } from '../hooks/user';
import { UserTableRow, displayItems } from './UserTableRow';

export const UserContent = () => {
  const users = useListUsers({
    limit: COMMON_QUERY_LIMIT,
    filter: { createdAt: { ge: MEETING_OLDEST_DATE } },
  });

  const rows = React.useMemo(() => {
    const tmp = users.filter((user) => user.createdAt);
    tmp.sort(
      (a, b) =>
        new Date(b.createdAt as string).getTime() -
        new Date(a.createdAt as string).getTime()
    );
    return tmp.map((user) => {
      const isJoinTeam = user.tenantUsers && user.tenantUsers.items.length > 1;
      return (
        <UserTableRow
          key={user.id}
          email={user.email}
          createdAt={user.createdAt || ''}
          userId={user.id}
          userName={user.name}
          joinTeam={isJoinTeam ? '✓' : ''}
        />
      );
    });
  }, [users]);
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Box sx={{ width: '100%' }}>
        <Table collapsible={false} columns={displayItems} rows={rows} />
      </Box>
    </Container>
  );
};
