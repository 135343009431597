import { WebSocketLink } from "@apollo/client/link/ws";
import { UUIDOperationIdSubscriptionClient } from "./UUIDOperationIdSubscriptionClient";
import { createGraphQLOperationAdapter } from "./graphQLOperationAdapter";

const WEBSOCKET_TIME_OUT = 61 * 1000; // 61sec. appsyncからのkeep-aliveが60秒間隔なので、60秒以下にするとOSSが切断と再接続を繰り返すようになる。1秒ゲタを履かせる。
const MAX_RECONNECT_COUNT = 5;
export type AuthHeaderCognito = {
  host: string;
  Authorization: string;
};
export type AuthHeaderSigV4 = {
  accept: string;
  "content-type": string;
  host: string;
  "x-amz-date": string;
  "X-Amz-Security-Token": string;
  Authorization: string;
};

const convertWebsocktUrl = (host: string) => {
  return `wss://${host.replace("appsync-api", "appsync-realtime-api")}/graphql`;
};
const log = (msg: string, wsState: number) => {
  const logMsg = `SubscClient.${new Date().toISOString()} ${msg} WsState: ${wsState}`;
  console.log(logMsg);
};

export const createSubscriptionClient = (
  appsyncApiUri: string,
  authFunc: () => Promise<AuthHeaderCognito | AuthHeaderSigV4>
): WebSocketLink => {
  const appsyncApiHost = new URL(appsyncApiUri).host;
  const wsUri = convertWebsocktUrl(appsyncApiHost);
  const client = new UUIDOperationIdSubscriptionClient(
    wsUri,
    {
      reconnect: true,
      lazy: true,
      timeout: WEBSOCKET_TIME_OUT,
      reconnectionAttempts: MAX_RECONNECT_COUNT,
      connectionCallback: (error: Error[]) =>
        error && log(`connectionCallback, ${JSON.stringify(error)}`, -1),
    },
    authFunc
  );

  client.onConnecting(() => {
    log(`Connecting`, client.status);
    client.changeState("connecting");
  });
  client.onConnected(() => {
    log(`Connected`, client.status);
    client.changeState("connected");
    client.reconnectingCount = 0;
  });
  client.onDisconnected(() => {
    log(
      `Disconnected. reconnectingCount:${client.reconnectingCount}`,
      client.status
    );
    client.reconnectingCount += 1;
    if (client.reconnectingCount === 1) {
      if (client.state !== "init") {
        client.changeState("reconnecting");
      }
    } else if (client.reconnectingCount > MAX_RECONNECT_COUNT) {
      client.changeState("disconnected");
    } else {
      // 再接続中.なにもしない
    }
  });
  client.onReconnecting(() => {
    log(`Reconnecting`, client.status);
  });
  client.onReconnected(() => {
    log(`Reconnected`, client.status);
    client.changeState("connected");

    client.reconnectingCount = 0;
    // callbacks.onSucceed('配信サーバーと再接続しました', true); // 頻繁に表示されるので一旦toast通知やめる。https://rdbc.backlog.com/view/PEKOE_DEV-1341
  });
  client.onError((err) => {
    log(`Error ${JSON.stringify(err)}`, client.status);
  });
  client.use([createGraphQLOperationAdapter(authFunc)]);

  // Privateをextendでオーバーライドする必要があるため
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return new WebSocketLink(client);
};
