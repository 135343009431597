import { ASTNode } from 'graphql';
import * as graphqlPrinter from 'graphql/language/printer';
import { OperationOptions } from 'subscriptions-transport-ws';
import { AuthHeaderCognito, AuthHeaderSigV4 } from './subscriptionClient';

export const createGraphQLOperationAdapter = (
  authFunc: () => Promise<AuthHeaderCognito | AuthHeaderSigV4>
) => {
  return {
    applyMiddleware: async (options: OperationOptions, next: () => void) => {
      // AppSync expects GraphQL operation to be defined as a JSON-encoded object in a "data" property
      options.data = JSON.stringify({
        query:
          typeof options.query === 'string'
            ? options.query
            : graphqlPrinter.print(options.query as ASTNode),
        variables: options.variables,
      });

      // AppSync only permits authorized operations
      options.extensions = { authorization: await authFunc() };

      // AppSync does not care about these properties
      delete options.operationName;
      delete options.variables;
      // Not deleting "query" property as SubscriptionClient validation requires it

      next();
    },
  };
};
