import * as React from 'react';
import {
  ClonalContractHistory,
  ListClonalContractHistoriesQueryVariables,
  useListClonalContractHistoriesLazyQuery,
} from '@/lib/graphql/clonal-appsync-apollo';
import { Maybe } from '@/types/types';
import { COMMON_OPTION } from '@/hooks/common';

type ModelType = ClonalContractHistory;
type QueryVariablesType = ListClonalContractHistoriesQueryVariables;
const useLazyQuery = useListClonalContractHistoriesLazyQuery;

export function useListContractsLazyQuery(
  onCompleted: (contractList: ModelType[]) => void
) {
  const [lazyQuery] = useLazyQuery({
    ...COMMON_OPTION,
    onCompleted: (data) => {
      const result = data?.listClonalContractHistories?.items;
      const nextToken = data?.listClonalContractHistories?.nextToken;
      if (result) {
        onCompleted?.(result as ModelType[]);
        nextToken &&
          variablesRef.current &&
          lazyQuery({
            variables: { ...variablesRef.current, nextToken },
          });
      }
    },
  });

  // ############ 共通部分 ############
  const variablesRef = React.useRef<Maybe<QueryVariablesType>>(undefined);
  const queryRef = React.useRef(lazyQuery);
  React.useEffect(() => {
    queryRef.current = lazyQuery;
  }, [lazyQuery]);

  // 非同期Hook用
  const func = React.useCallback((variables: QueryVariablesType) => {
    variablesRef.current = variables;
    queryRef.current({
      variables,
    });
  }, []);
  return func;
}

export function useListContracts(variables: QueryVariablesType) {
  const [lazyQuery] = useLazyQuery({
    ...COMMON_OPTION,
    onCompleted: (data) => {
      const result = data.listClonalContractHistories?.items as
        | ModelType[]
        | null;
      const nextToken = data?.listClonalContractHistories?.nextToken;
      if (result) {
        setValue((current) => [...current, ...result]);
        nextToken &&
          variablesRef.current &&
          lazyQuery({
            variables: { ...variablesRef.current, nextToken },
          });
      }
    },
  });

  // ############ 共通部分 ############
  const variablesRef = React.useRef<QueryVariablesType>(variables);
  const queryRef = React.useRef(lazyQuery);
  React.useEffect(() => {
    queryRef.current = lazyQuery;
  }, [lazyQuery]);

  // 同期Hook用
  const [value, setValue] = React.useState<ModelType[]>([]);
  React.useEffect(() => {
    queryRef.current({
      variables: variablesRef.current,
    });
  }, []);
  return value;
}
