import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from '@/components/app/App';
import reportWebVitals from './reportWebVitals';

window.addEventListener('unhandledrejection', (event) => {
  const message = `エラーが起きました: unhandled rejection at Promise: ${event.reason}`;
  console.error(
    'Unhandled rejection (promise: ',
    event.promise,
    ', reason: ',
    event.reason,
    ').'
  );
  alert(message);
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
