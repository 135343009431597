import { WatchQueryFetchPolicy } from '@apollo/client';

type CommonQueryOption = {
  fetchPolicy: WatchQueryFetchPolicy;
  notifyOnNetworkStatusChange: true;
  onError: (err: Error) => void;
};

export const COMMON_OPTION: CommonQueryOption = {
  fetchPolicy: 'cache-first',
  notifyOnNetworkStatusChange: true,
  onError: (err: Error) => {
    console.error(err);
  },
};
