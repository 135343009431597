import * as React from 'react';
import Box from '@mui/material/Box';
import { AppBar } from '@/components/frame/AppBar';
import { Drawer } from '@/components/frame/Drawer';
import { DrawerItemKind } from '@/components/frame/const';
import { MainContent } from '@/components/frame/MainContent';
import { DashboardContent } from '@/features/dashboard';
import { MeetingContent } from '@/features/meetings';
import { ContractContent } from '@/features/contracts';
import { UserContent } from '@/features/users';

export const DashBoardPageView = () => {
  const getDrawerContent = (item: DrawerItemKind) => {
    switch (item) {
      case 'Dashboard':
        return <DashboardContent />;
      case 'Meetings':
        return <MeetingContent />;
      case 'Contracts':
        return <ContractContent />;
      case 'Users':
        return <UserContent />;
      default:
        console.error('not implemented yet.');
        return <ContractContent />;
    }
  };

  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const [selectedDrawerItem, setSelectedDrawerItem] =
    React.useState<DrawerItemKind>('Contracts');
  const onSelectDrawerItem = (item: DrawerItemKind) => {
    setSelectedDrawerItem(item);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        open={open}
        title={selectedDrawerItem}
        toggleDrawer={toggleDrawer}
      />
      <Drawer
        open={open}
        toggleDrawer={toggleDrawer}
        onSelect={onSelectDrawerItem}
      />
      <MainContent>{getDrawerContent(selectedDrawerItem)} </MainContent>
    </Box>
  );
};
