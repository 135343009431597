import React from 'react';
import { ApolloClient } from '@apollo/client/core';
import { ApolloProvider } from '@apollo/client/';
import { NormalizedCacheObject } from '@apollo/client/cache';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { createClient } from '@/lib/appsync/appsyncClient';
import { Root } from '@/routes/Root';

const mdTheme = createTheme();

type Props = {
  signOut: () => void;
};

export const AppBody = (props: Props) => {
  const [client, setClient] = React.useState<
    ApolloClient<NormalizedCacheObject> | undefined
  >(undefined);

  React.useEffect(() => {
    const getClient = async () => {
      setClient(await createClient());
    };
    getClient();
  }, []);
  return (
    <>
      {client ? (
        <ApolloProvider client={client}>
          <ThemeProvider theme={mdTheme}>
            <CssBaseline />
            <Root />
          </ThemeProvider>
        </ApolloProvider>
      ) : (
        <></>
      )}
    </>
  );
};
