import { differenceInDays } from 'date-fns';
import { SxProps, Theme } from '@mui/material';
import {
  ClonalContractHistory,
  ClonalTenant,
  ClonalMeetingRecord,
} from '@/lib/graphql/clonal-appsync-apollo';
import { Maybe } from '@/types/types';

/* 表示を見やすくする系 */
export const getReadablePaymentType = (paymentType: string) => {
  if (paymentType === 'cotos') {
    return 'toruno有料';
  } else if (paymentType === 'pekoeBilling') {
    return 'pekoe有料';
  } else if (paymentType === 'trial') {
    return 'トライアル';
  } else {
    return paymentType;
  }
};

export const getMeetingTotalMinutes = (
  meetingRecords: ClonalMeetingRecord[]
) => {
  return meetingRecords.reduce(
    (prev, cur) => prev + (cur?.meetingTime || 0),
    0
  );
};

export const getTextStyleOfTrialExpired = (
  expiredAt: Maybe<string>
): SxProps<Theme> => {
  if (!expiredAt) return { color: 'inherit', fontWeight: 'bold' };

  const diffInDays = differenceInDays(new Date(expiredAt), new Date());
  const EXPIRE_WARNING_DAYS = 365;
  if (diffInDays >= EXPIRE_WARNING_DAYS) {
    return { color: 'inherit', fontWeight: 'bold' };
  } else if (0 <= diffInDays && diffInDays < EXPIRE_WARNING_DAYS) {
    return { color: 'navy', fontWeight: 'bold' };
  } else {
    return { color: 'gray', fontWeight: 'bold' };
  }
};

/* データモデル整形系 */
type ContractAndTenant = {
  contract: ClonalContractHistory;
  tenant: Maybe<ClonalTenant>;
};
export const mergeContractWithTenant = (
  contracts: ClonalContractHistory[],
  tenants: ClonalTenant[]
): ContractAndTenant[] => {
  if (!(contracts.length > 0 && tenants.length > 0)) return [];
  const ctnrcs = [...contracts];
  ctnrcs.sort(
    (a, b) =>
      new Date(b.contractStartedAt).getTime() -
      new Date(a.contractStartedAt).getTime()
  );
  return ctnrcs.map((contract) => {
    const tenant = tenants.find((tenant) => tenant.id === contract.tenantID);
    return { contract, tenant };
  });
};
