import * as React from 'react';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import Divider from '@mui/material/Divider';
import { REPORT_ITEMS, DrawerItemKind } from './const';
import { DrawerItem } from './DrawerItem';
type Props = {
  onSelect: (item: DrawerItemKind) => void;
};
export const DrawerList = (props: Props) => {
  const handleOnSelect = React.useCallback(
    (item: DrawerItemKind) => {
      const onSelect = props.onSelect;
      onSelect(item);
    },
    [props.onSelect]
  );

  return (
    <>
      <List component="nav">
        <DrawerItem itemName="Dashboard" onClick={handleOnSelect} />
        <Divider />
        <ListSubheader component="div" inset>
          Reports
        </ListSubheader>
        {REPORT_ITEMS.map((item) => {
          return (
            <DrawerItem key={item} itemName={item} onClick={handleOnSelect} />
          );
        })}
      </List>
    </>
  );
};
