import * as React from 'react';
import { Table as MuiTable } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { reducer, State } from './Table.reducer';
import { RowItem } from './CollapsibleTableRow';
import { TablePagination } from './TablePagination';
import { SortableCell } from './SortableCell';

const ROWS_PER_PAGE = [10, 20, 50, 100];
const INITIAL_STATE: State = {
  currentPage: 0,
  rowsPerPage: 20,
  startIdx: 0,
  endIdx: 20,
  sortActiveColumn: undefined,
};

type Props = {
  columns: RowItem[];
  rows: JSX.Element[];
  collapsible: boolean;
};

export function Table(props: Props) {
  const [state, dispatch] = React.useReducer(reducer, INITIAL_STATE);

  const sortedRows = React.useMemo(() => {
    return props.rows;
  }, [props.rows]);

  const currentPageItem = React.useMemo(() => {
    return sortedRows.slice(state.startIdx, state.endIdx);
  }, [sortedRows, state]);

  return (
    <>
      <TableContainer component={Paper}>
        <MuiTable size="small" aria-label="table">
          <TableHead>
            <TableRow>
              {props.collapsible && <TableCell />}
              {props.columns.map((column, idx) =>
                column.sortable ? (
                  <SortableCell
                    key={column.header}
                    idx={idx}
                    active={state.sortActiveColumn?.idx === idx}
                    value={column.header}
                    dispatch={dispatch}
                  />
                ) : (
                  <TableCell key={column.header}>{column.header}</TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>{currentPageItem}</TableBody>
        </MuiTable>
      </TableContainer>
      <TablePagination
        dispatch={dispatch}
        rowsPerPageOptions={ROWS_PER_PAGE}
        count={props.rows.length}
        rowsPerPage={state.rowsPerPage}
        currentPage={state.currentPage}
      />
    </>
  );
}
