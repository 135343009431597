import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { COMMON_QUERY_LIMIT } from '@/const/define';
import { Table } from '@/components/table/Table';
import { useListContracts } from '../hooks/contract';
import { useListTenants } from '../hooks/tenant';
import { mergeContractWithTenant } from '../utils/contractUtils';
import { ContractTableRow, displayItems } from './ContractTableRow';

export const ContractContent = () => {
  const contracts = useListContracts({ limit: COMMON_QUERY_LIMIT });
  const tenants = useListTenants({ limit: COMMON_QUERY_LIMIT });
  const contractWithTenant = React.useMemo(() => {
    return mergeContractWithTenant(contracts, tenants);
  }, [contracts, tenants]);

  const rows = React.useMemo(() => {
    return !contractWithTenant
      ? [<></>]
      : contractWithTenant.map((elm) => {
          return (
            <ContractTableRow
              key={elm.contract.id}
              contract={elm.contract}
              tenant={elm.tenant}
            />
          );
        });
  }, [contractWithTenant]);

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Box sx={{ width: '100%' }}>
        <Table collapsible columns={displayItems} rows={rows} />
      </Box>
    </Container>
  );
};
