import { subDays } from 'date-fns';

/* 共通 */
export const COMMON_QUERY_LIMIT = 1000;

/* Meeting系 */
export const MEETING_DAYS_AGO = 100;
export const MEETING_OLDEST_DATE = subDays(
  new Date(),
  MEETING_DAYS_AGO
).toISOString();

export const APP_BAR_TITLE_FOR_MEETING = `Meetings(〜${MEETING_DAYS_AGO}日前まで)`;

/* User系 */
export const USER_DAYS_AGO = 100;
export const USER_OLDEST_DATE = subDays(
  new Date(),
  USER_DAYS_AGO
).toISOString();

export const APP_BAR_TITLE_FOR_USER = `Users(〜${USER_DAYS_AGO}日前まで)`;
