import * as React from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import { DrawerItemKind } from './const';

type Props = {
  itemKind: DrawerItemKind;
};
export const DrawerIcon = (props: Props) => {
  const icon = React.useMemo(() => {
    switch (props.itemKind) {
      case 'Dashboard':
        return <DashboardIcon />;
      case 'Meetings':
        return <BarChartIcon />;
      case 'Users':
        return <PeopleIcon />;
      case 'Contracts':
        return <DashboardIcon />;
      default:
        return <DashboardIcon />;
    }
  }, [props.itemKind]);
  return <ListItemIcon>{icon}</ListItemIcon>;
};
