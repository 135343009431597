import * as React from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { COMMON_QUERY_LIMIT } from '@/const/define';
import { useListTenantUsers } from '../hooks/tenantUser';
import { useListMeetingRecord } from '../hooks/meetingRecord';
import { getMeetingTotalMinutes } from '../utils/contractUtils';

type Props = {
  contractId: string;
  tenantId: string;
};

export function ContractCollapsedContent(props: Props) {
  const users = useListTenantUsers({
    tenantID: props.tenantId,
    limit: COMMON_QUERY_LIMIT,
  });
  const meetingRecords = useListMeetingRecord({
    contractHistoryID: props.contractId,
    limit: COMMON_QUERY_LIMIT,
  });

  const Header = React.useMemo(() => {
    return (
      <TableHead>
        <TableRow
          sx={{
            '& > *': {
              borderBottom: 'unset',
              wordWrap: 'break-word',
              whiteSpace: 'nowrap',
            },
          }}
        >
          <TableCell align="left">チームメンバー数</TableCell>
          <TableCell align="left">会議回数</TableCell>
          <TableCell align="left">会議時間(分)</TableCell>
        </TableRow>
      </TableHead>
    );
  }, []);

  const Body = React.useMemo(() => {
    return (
      <TableBody>
        <TableRow>
          <TableCell component="th" scope="row">
            {users.length}
          </TableCell>
          <TableCell align="left">{meetingRecords.length}</TableCell>
          <TableCell align="left">
            {getMeetingTotalMinutes(meetingRecords)}
          </TableCell>
        </TableRow>
      </TableBody>
    );
  }, [users, meetingRecords]);
  return (
    <Box sx={{ margin: 1 }}>
      <Typography variant="h6" gutterBottom component="div">
        テナント詳細
      </Typography>
      <Table size="small" aria-label="tenant-details">
        {Header}
        {Body}
      </Table>
    </Box>
  );
}
