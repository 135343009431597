import { WebSocketLink } from "@apollo/client/link/ws";
import {
  AuthHeaderCognito,
  createSubscriptionClient,
} from "./subscriptionClient";

export type IamCredentials = {
  accessKeyId: string;
  secretAccessKey: string;
  sessionToken: string;
};

export const createSubscriptionLinkForCognitoAuth = async (
  appsyncApiUri: string,
  getAuthorization: () => Promise<string>
): Promise<WebSocketLink> => {
  const getAuthHeaders = async (): Promise<AuthHeaderCognito> => {
    return getCognitoAuthHeaders(appsyncApiUri, getAuthorization);
  };

  return createSubscriptionClient(appsyncApiUri, getAuthHeaders);
};

const getCognitoAuthHeaders = async (
  appsyncApiUri: string,
  getAuthorization: () => Promise<string>
) => {
  const appSyncApiHost = new URL(appsyncApiUri).host;
  return {
    host: appSyncApiHost,
    Authorization: await getAuthorization(),
  };
};
