import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { MEETING_OLDEST_DATE, COMMON_QUERY_LIMIT } from '@/const/define';
import { Table } from '@/components/table/Table';
import { useListMeetings } from '../hooks/meeting';
import { MeetingTableRow, displayItems } from './MeetingTableRow';

export const MeetingContent = () => {
  const meetings = useListMeetings({
    limit: COMMON_QUERY_LIMIT,
    filter: { createdAt: { ge: MEETING_OLDEST_DATE } },
  });

  const rows = React.useMemo(() => {
    return meetings.map((meeting) => {
      return (
        <MeetingTableRow
          key={meeting.id}
          title={meeting.name}
          createdAt={meeting.createdAt || ''}
          userId={meeting.organizerID}
          dummy1={meeting.passcode || ''}
          dummy2={meeting.endedAt || ''}
        />
      );
    });
  }, [meetings]);
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Box sx={{ width: '100%' }}>
        <Table collapsible columns={displayItems} rows={rows} />
      </Box>
    </Container>
  );
};
