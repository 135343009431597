import * as React from 'react';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableCell from '@mui/material/TableCell';
import { Order } from '@/types/types';
import { Action } from './Table.reducer';

type Props = {
  value: string;
  idx: number;
  active: boolean;
  dispatch: React.Dispatch<Action>;
};

export function SortableCell(props: Props) {
  const [order, setOrder] = React.useState<Order>('asc');
  const handleOnClick = React.useCallback(() => {
    let newOrder: Order = 'asc';
    setOrder((prev) => {
      let order: Order = 'asc';
      if (props.active) {
        order = prev === 'asc' ? 'desc' : 'asc';
      } else {
        order = prev;
      }
      newOrder = order;
      return order;
    });
    const dispatch = props.dispatch;
    dispatch({ type: 'sortBy', idx: props.idx, order: newOrder });
  }, [props.active, props.dispatch, props.idx]);
  return (
    <TableCell sx={{ fontWeight: 'bold' }}>
      <TableSortLabel
        onClick={handleOnClick}
        active={props.active}
        direction={order}
      >
        {props.value}
      </TableSortLabel>
    </TableCell>
  );
}
