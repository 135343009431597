import * as React from 'react';
import { TablePagination as MuiTablePagination } from '@mui/material';
import { Action } from './Table.reducer';

type Props = {
  dispatch: React.Dispatch<Action>;
  count: number;
  rowsPerPageOptions: number[];
  rowsPerPage: number;
  currentPage: number;
};

export function TablePagination(props: Props) {
  const handleOnPageChange = React.useCallback(
    (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
      page: number
    ) => {
      const dispatch = props.dispatch;
      dispatch({ type: 'movePageTo', pageNum: page });
    },
    [props.dispatch]
  );

  const handleOnRowsPerPageChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const dispatch = props.dispatch;
      dispatch({
        type: 'changeRowsPerPage',
        value: parseInt(event.target.value, 10),
      });
    },
    [props.dispatch]
  );

  return (
    <MuiTablePagination
      rowsPerPageOptions={props.rowsPerPageOptions}
      component="div"
      count={props.count}
      rowsPerPage={props.rowsPerPage}
      page={props.currentPage}
      onPageChange={handleOnPageChange}
      onRowsPerPageChange={handleOnRowsPerPageChange}
    />
  );
}
