import { setContext } from '@apollo/client/link/context';
import { ApolloLink, HttpLink } from '@apollo/client';
import { Auth } from 'aws-amplify';
import {
  AUTH_TYPE,
  createAuthLink as awsCreateAuthLink,
} from 'aws-appsync-auth-link';

export const createHttpLinkForCognitoAuth = async (
  uri: string,
  getAuthorization: () => Promise<string>
) => {
  // Headerに認証情報を追記
  const authorizationHeaderLink = setContext(
    async (request, previousContext) => ({
      ...previousContext,
      headers: {
        ...previousContext.headers,
        Authorization: await getAuthorization(),
      },
    })
  );
  return ApolloLink.from([authorizationHeaderLink, new HttpLink({ uri })]);
};

export const createHttpLinkForIamAuth = async (uri: string, region: string) => {
  const authLink = awsCreateAuthLink({
    url: uri,
    region,
    auth: {
      credentials: () => Auth.currentCredentials(),
      type: AUTH_TYPE.AWS_IAM,
    },
  });
  return ApolloLink.from([authLink, new HttpLink({ uri })]);
};
