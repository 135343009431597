import * as React from 'react';

import {
  CollapsibleTableRow,
  RowItem,
} from '@/components/table/CollapsibleTableRow';
import { MeetingCollapsedContent } from './MeetingCollapsedContent';

export const displayItems: RowItem[] = [
  { header: 'タイトル', value: '' },
  { header: '作成日', value: '' },
  { header: 'ユーザーID', value: '' },
  { header: 'dummy1', value: '' },
  { header: 'dummy2', value: '' },
];

type Props = {
  title: string;
  createdAt: string;
  userId: string;
  dummy1: string;
  dummy2: string;
};
export function MeetingTableRow(props: Props) {
  const rowItems: RowItem[] = React.useMemo(() => {
    const tmp = [...displayItems];
    tmp[0].value = props.title;
    tmp[1].value = props.createdAt;
    tmp[2].value = props.userId;
    tmp[3].value = props.dummy1;
    tmp[4].value = props.dummy2;
    return tmp;
  }, [props]);

  return (
    <CollapsibleTableRow
      rowItems={rowItems}
      collapsedContent={<MeetingCollapsedContent />}
    />
  );
}
