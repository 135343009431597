import { ApolloClient, ApolloLink, fromPromise } from '@apollo/client/core';
import { InMemoryCache, NormalizedCacheObject } from '@apollo/client/cache';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { Auth } from 'aws-amplify';
import { getEnvConfig } from '@/config/envConfig';
import { createHybridLinkForCognitoAuth } from './subscription-websocket-client/hybridLink';

const defaultEnvConfig = getEnvConfig();

const getNewToken = async (): Promise<string> => {
  try {
    const token = (await Auth.currentSession()).getAccessToken().getJwtToken();
    // printTokenTime(token);
    return token;
  } catch (err) {
    console.log(`getNewToken err: ${err}, ${JSON.stringify(err)} `);
    throw err;
  }
};

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    // console.log(`[graphQLError]: ${JSON.stringify(graphQLErrors)}`);
    // console.log(`[networkError]: ${JSON.stringify(networkError)}`);
    console.log(
      `oldheaders : ${JSON.stringify(operation.getContext().headers)}`
    );

    // エラーが起きたときはトークン更新してrertyするようにしている
    // 参考: https://stackoverflow.com/questions/61327448/how-to-refresh-jwt-token-using-apollo-and-graphql
    return fromPromise(
      getNewToken().catch(() => {
        console.error(`[App.tsx] getNewToken() failed!!`);
        return;
      })
    )
      .filter((value) => Boolean(value))
      .flatMap((accessToken) => {
        // console.log(`retry with new access token: ${accessToken}`);
        const oldHeaders = operation.getContext().headers;
        operation.setContext({
          headers: {
            ...oldHeaders,
            Authorization: `${accessToken}`,
          },
        });

        return forward(operation);
      });
  }
);

const retryLink = new RetryLink({
  delay: { initial: 1000 },
  attempts: { max: 5 },
});

export const createClient = async (): Promise<
  ApolloClient<NormalizedCacheObject>
> => {
  return new ApolloClient({
    link: ApolloLink.from([
      errorLink,
      retryLink,
      await createHybridLinkForCognitoAuth(
        defaultEnvConfig.aws_appsync_graphqlEndpoint,
        getNewToken
      ),
    ]),
    cache: new InMemoryCache(),
    connectToDevTools: false,
  });
};
