/* eslint-disable @typescript-eslint/naming-convention */
import { EnvConfig } from "./env-config";

const debugEnvConfig: EnvConfig = {
  env_name: "mano",
  aws_project_region: "ap-northeast-1",
  aws_cognito_identity_pool_id:
    "ap-northeast-1:d7fafb06-f983-467e-a01f-2e70ce6ec7ce",
  aws_cognito_region: "ap-northeast-1",
  aws_user_pools_id: "ap-northeast-1_PiVir9qe9",
  aws_user_pools_web_client_id: "1uq5rhld3f2bqfcpdpj1p3h133",
  oauth: {},
  aws_cloud_logic_custom: [
    {
      name: "AdminQueries",
      endpoint:
        "https://i7as78qu7j.execute-api.ap-northeast-1.amazonaws.com/mano",
      region: "ap-northeast-1",
    },
    {
      name: "RestAPI",
      endpoint: "https://rest.mano.clonal.link",
      region: "ap-northeast-1",
    },
  ],
  aws_appsync_graphqlEndpoint:
    "https://wc234mvk25dlvnw72txkjdkxwe.appsync-api.ap-northeast-1.amazonaws.com/graphql",
  aws_appsync_region: "ap-northeast-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  aws_user_files_s3_bucket: "clonal-s3-contents184731-mano",
  aws_user_files_s3_bucket_region: "ap-northeast-1",
  aws_mobile_analytics_app_id: "95845f1e2a6849cf9446b880ee4246cf",
  aws_mobile_analytics_app_region: "us-west-2",
  aws_content_delivery_bucket: "clonal-hosting-mano",
  aws_content_delivery_bucket_region: "ap-northeast-1",
  aws_content_delivery_url: "https://app.mano.clonal.link",
};

export default debugEnvConfig;
