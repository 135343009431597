import * as React from 'react';
import { RowItem } from '@/components/table/CollapsibleTableRow';
import { UnCollapsibleTableRow } from '@/components/table/UnCollapsibleTableRow';

export const displayItems: RowItem[] = [
  { header: 'EMail', value: '' },
  { header: 'ユーザー名', value: '' },
  { header: '登録日', value: '' },
  { header: 'チーム所属', value: '' },
];
type Props = {
  email: string;
  userName: string;
  userId: string;
  createdAt: string;
  joinTeam: string;
};
export function UserTableRow(props: Props) {
  const rowItems = React.useMemo(() => {
    const tmp = [...displayItems];
    tmp[0].value = props.email;
    tmp[1].value = props.userName;
    tmp[2].value = props.createdAt;
    tmp[3].value = props.joinTeam;
    return tmp;
  }, [props]);

  return <UnCollapsibleTableRow rowItems={rowItems} />;
}
